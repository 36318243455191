.container {
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
}

.hook {
    height: 25vh;
    max-height: 250px;
}

.card {
    text-align: center;
    border-radius: 8px;
    background-color: #f5cd0b;
    width: 80%;
    max-width: 640px;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    overflow-x: hidden;
    border: 1px solid black;
}

.card_edge {
    background-repeat: repeat;
    height: 2em;
    border: 1px solid black;
}

.mirror {
    transform: scaleY(-1);
}

.content {
    margin: 2em 0.5em;
    font-size: 1.75em;
    font-family: 'Ubuntu', sans-serif;
}