.submenuContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    z-index: -1;
    font-size: 80%;
    height: auto;
    margin-bottom: .5em !important;
}

.submenuContainer a {
    margin: .4em 0 !important;
}

.absolute {
    position: absolute;
    padding: 0 8px 4px 8px !important;
}

.absolute a {
    margin: .3em 0 !important;
}

.absolute a:hover,
.absolute a:active {
    color: #ff9900 !important;
}

.openInit {
    transform: translateY(-100%);
}

.open {
    transform: translateY(0%);
    transition: .3s ease-in-out;
}

.closeInit {
    transform: translateY(0%);
}

.close {
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

/* Tablet */
@media (min-width: 768em) {
    .submenuContainer a {
        margin: .5em 0 !important;
    }
}