.navigationItem {
    text-decoration: none;
    display: block;
}

.navigationItem:hover,
.navigationItem.active {
    text-decoration: underline;
}

.row {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 .5em;
}

.row.navigationItem:hover,
.row.navigationItem.active {
    color: #ff9900 !important;
}

.backgroundToolbar {
    background: linear-gradient(#2C2F33, #36393F);
}

.column {
    margin: .25em 0;
}

/* Tablet */
@media (min-width: 33em) {
    .column {
        margin: .5em 0;
    }
}