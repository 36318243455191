.Modal {
    position: fixed;
    z-index: 100;
    width: 70%;    
    left: 15%;
    top: 30%;
    height: 35%;
    min-height: 35%;
    box-sizing: border-box;
    border-radius: 16px 16px 32px 32px;
    border: 2px black solid;
    background: #c8c8c8;
    display: flex;
    flex-direction: column;
}

.Title {
    font-size: 2.25rem;
    font-weight: bolder;
    padding: .5rem 0;
    margin-top: .25rem;
    border-bottom: 2px black solid;
    text-align: center;
}

.Content {
    min-height: 200px;
    text-align: center;
    padding: 16px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
}

.ErrorMessage {
    font-size: 1.4rem;
    border: 1px solid black;
    padding: 16px;
    border-radius: 8px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.SubText {
    font-size: 1rem;
    margin-top: .75rem;
}

.SubText a {
    color: black;
}