@keyframes move {
    0% {
        -webkit-transform:translate3d(0,15%,0);
        transform:translate3d(0,15%,0);
    }
    to {
        -webkit-transform:translateZ(0);
        transform:translateZ(0);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.card {
    border: 1px solid #c8c8c8;
    background-color: #4f4f4f;
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
    min-height: 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    color: white;
    margin: 1px 0;
    position: relative;
    opacity: 0;
    -webkit-transform:translate3d(0,15%,0);
    transform:translate3d(0,15%,0);
    animation-name: move, fade;
    animation-duration: .4s, .6s;
    animation-fill-mode: forwards;
}

.delivery {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px .5em;
    font-size: 1.1em;
    text-shadow: -1px -1px 2px black, 1px -1px 2px black, 1px 1px 2px black, -1px 1px 2px black;
    font-family: 'Roboto', sans-serif;
    z-index: 10;
}

.badges {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    height: 20%;
    max-height: 64px;
    z-index: 5;
    margin: 8px;
}

.badge {
    height: 100%;
    margin-right: 4px;
}

.badge>img {
    height: 100%;
}

.title {
    font-weight: bold;
    margin: 0 .75rem;
    font-size: 1.4rem;
    text-shadow: -1px -1px 2px black, 1px -1px 2px black, 1px 1px 2px black, -1px 1px 2px black;
    font-family: 'Kumbh Sans', sans-serif;
}

.card:hover, .card:focus {
    border-color: black;
}

.card:hover .title {
    text-decoration: underline white !important;
}


@media screen and (orientation: Landscape) {
    .card {
        min-height: 160px;
    }

    .title {
        font-size: 1.6rem;
    }
}

@media screen and (min-width: 480px) {
    .title {
        text-align: center;
        width: 100%;
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 768px) and (orientation: Portrait) {
    .card {
        margin: 0;
        justify-content: center;
        text-align: center;
    }

    .title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 992px) {
    .card {
        margin: 0;
        min-height: 120px;
        justify-content: center;
        text-align: center;
    }

    .title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1200px) {
    .card {
        min-height: 160px;
    }

    .title {
        font-size: 1.6rem;
    }
}