.toolbarHeight {
    height: 2.5em;
}

.contentHeight {
    height: 100%;
    padding-top: 2.5em;
}

/* Touch */
@media (hover: none) {
    .touch-Only {

    }

    .noTouch {
        display: none !important;
    }
}
/* No Touch */
@media (hover: hover) {
    .touch-Only {
        display: none !important;
    }

    .noTouch {

    }
}

/* Mobile Portrait */
@media screen and (max-width: 479.98px) {
    .mobile { }

    .notOnMobile {
        display: none !important;
    }

    .tabletOnly {
        display: none !important;
    }

    .desktopOnly {
        display: none !important;
    }

    .toolbarHeight {
        height: 2.5em;
    }

    .contentHeight {
        height: 100%;
        padding-top: 2.5em;
    }
}

/* Mobile Landscape */
@media screen and (orientation: Landscape) and (min-width: 480px) and (max-width: 767.98px) {
    .mobile { }

    .notOnMobile {
        display: none !important;
    }

    .tabletOnly {
        display: none !important;
    }

    .desktopOnly {
        display: none !important;
    }

    .toolbarHeight {
        height: 2.75em;
    }

    .contentHeight {
        height: 100%;
        padding-top: 2.75em;
    }
}

/* Tablet Portrait */
@media screen and (orientation: Portrait) and (min-width: 768px) and (max-width: 991.98px) {
    .tablet { }

    .notOnTablet {
        display: none !important;
    }

    .mobileOnly {
        display: none !important;
    }

    .desktopOnly {
        display: none !important;
    }

    .toolbarHeight {
        height: 2.5em;
    }

    .contentHeight {
        height: 100%;
        padding-top: 2.5em;
    }
}

/* Tablet Landscape */
@media screen and (orientation: Landscape) and (min-width: 992px) and (max-width: 1199.98px) {
    .tablet { }

    .notOnTablet {
        display: none !important;
    }

    .mobileOnly {
        display: none !important;
    }

    .desktopOnly {
        display: none !important;
    }

    .toolbarHeight {
        height: 2.75em;
    }

    .contentHeight {
        height: 100%;
        padding-top: 2.75em;
    }
}

/* Laptops (and larger) */
@media screen and (min-width: 1200px) {
    .desktop { }

    .notOnDesktop {
        display: none !important;
    }

    .mobileOnly {
        display: none !important;
    }

    .tabletOnly {
        display: none !important;
    }

    .toolbarHeight {
        height: 2.75em;
    }

    .contentHeight {
        height: 100%;
        padding-top: 2.75em;
    }
}