.toolbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(#2C2F33, #36393F);
    z-index: 10;
    padding: 0 .75em;
    font-family: 'Noto Sans HK', sans-serif;
}

.menuButton {
    height: 50%;
    width: 1.75em;
}

.logo {
    text-align: center !important;
    height: 85%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    z-index: 100;
    cursor: pointer;
}

.logo > img {
    height: 100%;
}

.logoNormal {
    display: block;
}

.logoHover {
    display: none;
}

.logo:hover > .logoNormal {
    display: none;
}

.logo:hover > .logoHover {
    display: block;
}

.navigation {
    height: 100%;
    margin-right: .25em;
}

/* Tablet */
@media (min-width: 33em) {
    /* MenuButton increase width */
    .menuButton {
        width: 2em;
    }
    /* Font-Sizes */
    .logo a {
        font-size: 1.2em;
    }
    .navigation {
        font-size: 1.2em;
    }
}

/* Mid-Size Tablet */
@media (min-width: 47em) {
    /* Move Logo to Center */
    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

/* Desktop */
@media (min-width: 49em) {
    /* Move MenuItems to Right */
    .navigation {
        position: absolute;
        right: 0;
        margin-right: .75em;
    }
    /* Font-Sizes */
    .logo a {
        font-size: 1.3em;
    }
    .navigation {
        font-size: 1.3em;
    }
}