.hamburgerButton {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.hamburgerButton div {
    width: 100%;
    height: 2px;
}

/* Tablet */
@media (min-width: 33em) {
    /* Increase thickness of lines */
    .hamburgerButton div {
        height: 3px;
    }
}