.navigationItems {
    width: 100%;
    display: flex;
    list-style-type: none !important;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.navigationItems ul {
    list-style-type: none !important;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.column ul {
    padding-left: 1em;
}

.row {
    flex-direction: row;
    height: 100%;
}

.column {
    flex-direction: column;
}