.drawer {
    width: 80%;
    max-width: 400px;
    min-height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background: white;
    border-radius: 0 16px 16px 0;
    overflow-y: auto;
    font-family: 'Fira Sans', sans-serif;
}

.items {
    min-height: 100%;
    padding: 1.4em .25em;
    padding-left: .75em;
    font-size: 1.8em;
}

.items > ul > *:first-child {
    font-size: 1.4em;
}

.open .drawer {
    animation: openDrawer 0.4s ease-in-out forwards;
}

.close .drawer {
    animation: closeDrawer 0.3s ease-out forwards;
}

.open .background {
    animation: fadeInBackground 0.4s ease-out forwards;
}
.close .background {
    animation: fadeOutBackground 0.3s linear forwards;
}

/* Tablet */
@media screen and (min-width: 33em) {
    .drawer {
        width: 60%;
        max-width: 400px;
    }
}

@media screen and (max-height: 440px) {
    .drawer {
        font-size: 85% !important;
    }
}

@media screen and (max-height: 300px) {
    .drawer {
        font-size: 60% !important;
    }
}

@keyframes openDrawer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes closeDrawer {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes fadeInBackground {
    0% {
        opacity: 0;
    }
    100% {
        opacity: .6;
    }
}

@keyframes fadeOutBackground {
    0% {
        opacity: .6;
    }
    60% {
        opacity: .5;
    }
    100% {
        opacity: 0;
    }
}