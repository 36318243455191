.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.container {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 98%;
    background-color: #c8c8c8;
    margin-top: .5rem;
    border: 2px solid black;
    border-radius: 16px 16px 0 0;
    border-bottom: none;
    padding: 0;
}

.projectType {
    width: 33.329%;
    text-align: center;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: .75rem;
    color: black;
    text-decoration: none;
    padding: 12px 0;
    overflow: hidden;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.header>:first-child {
    border-radius: 12px 0 0 0;
    border-right: 2px solid black;
}

.header>:last-child {
    border-radius: 0 12px 0 0;
    border-left: 2px solid black;
}

.projectType:hover {
    text-decoration: underline;
}

.header>.active {
    text-decoration: underline;
    font-weight: 750;
    background-color: #f4f4f4;
}

.projectContainer {
    border: 2px solid black;
    border-radius: 0 0 4px 4px;
    height: 100%;
    width: 98%;
    margin: 2px 8px;
    margin-top: 0;
    background-color: #c8c8c8;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error {
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.cards {
    width: 100%;
    height: auto;
}

@media screen and (min-width: 480px) {
    .container {
        max-width: 800px;
    }
    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2px;
        grid-column-gap: 1px;
        padding: 2px 1px;
    }
    .projectType {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 768px) {
    .container {
        max-width: 800px;
    }
    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 2px;
        grid-column-gap: 1px;
        padding: 2px 1px;
    }
    .projectType {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 992px) {
    .container {
        max-width: 800px;
    }
    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 2px;
        grid-column-gap: 1px;
        padding: 2px 1px;
    }
}

@media screen and (min-height: 768px) and (min-width: 480px) {
    .projectType {
        padding: 24px 0;
        font-size: 1.2rem;  
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
    .projectType {
        padding: 32px 0;
        font-size: 1.4rem;  
    }
}